import { log } from "../../utils";
import { PageUpdateV3 } from "../models";
import { IEventOptions, PageBase } from "./base";
import {
  IDocumentReady,
  IInitCapture,
  IRequest,
  IRequestCleanup,
  IRequestFlush,
  IRequestRegisterDocument,
  IRequestRender,
  IResponse,
} from "./deprecated/messages.types";
import { IFrameOptions, Iframe } from "./iframe";
import {
  AbsolutePositionRequest,
  AbsolutePositionReset,
  Batch,
  CanvasImageBitmap,
  Cleanup,
  DocumentRegistered,
  FlushRequest,
  FlushResponse,
  PageUpdate,
  PleaseRegister,
  RegistrationRequest,
  RenderEvent,
  SetEvents,
  StatusRequest,
} from "./message-events.types";
import { Page } from "./page";

export enum COMPAT_MODE {
  UNDEF,
  OLD,
  NEW,
}

export class Messenger {
  public port: MessagePort;
  public modes = new WeakMap<WindowProxy, COMPAT_MODE>();

  constructor(public target: PageBase) {
    this.target.getWindow().addEventListener("message", this.onMessage);
  }

  public usesOldMode(win: WindowProxy) {
    return this.modes.get(win) === COMPAT_MODE.OLD;
  }

  public maybeUsesOldMode(win: WindowProxy) {
    return this.modes.has(win) && this.modes.get(win) !== COMPAT_MODE.NEW;
  }

  public setupRoot() {
    if (this.target.isRoot) {
      const mc = new MessageChannel();
      this.port = mc.port2;
      this.port.onmessage = this.onWorkerMessage;

      return mc.port1;
    }
  }

  public sendPleaseRegister(id: number, dest: WindowProxy) {
    dest.postMessage(
      {
        type: "PleaseRegister",
        module: "AuviousCobrowser",
        id,
      } as PleaseRegister,
      "*"
    );
  }

  public sendStatusRequest() {
    this.target.getWindow().parent.postMessage(
      {
        type: "StatusRequest",
        module: "AuviousCobrowser",
        signature: this.target.signature,
      } as StatusRequest,
      "*"
    );
  }

  public sendRegistrationRequest(id: number) {
    this.target.getWindow().parent.postMessage(
      {
        type: "RegistrationRequest",
        module: "AuviousCobrowser",
        signature: this.target.signature,
        id,
      } as RegistrationRequest,
      "*"
    );
  }

  public sendDocumentRegistered(
    id: number,
    init: IFrameOptions,
    dest: WindowProxy
  ) {
    const mc = new MessageChannel();
    const data: DocumentRegistered = {
      type: "DocumentRegistered",
      module: "AuviousCobrowser",
      port: mc.port1,
      id,
      init,
    };

    this.port.postMessage(data, [mc.port1]);

    data.port = mc.port2;
    dest.postMessage(data, "*", [mc.port2]);
  }

  public sendAbsolutePositionReset(
    coords: AbsolutePositionReset["coords"],
    dest: WindowProxy
  ) {
    const mode = this.modes.get(dest);

    if (mode !== COMPAT_MODE.OLD)
      dest.postMessage(
        {
          type: "AbsolutePositionReset",
          module: "AuviousCobrowser",
          coords,
        } as AbsolutePositionReset,
        "*"
      );

    if (mode !== COMPAT_MODE.NEW)
      dest.postMessage(
        {
          type: "Request",
          module: "AuviousCobrowser",
          payload: { method: "setPosition", payload: coords },
        } as IRequest,
        "*"
      );
  }

  /**
   * @deprecated
   */
  public sendInitCapture(init: IInitCapture["payload"], dest: WindowProxy) {
    if (!this.modes.has(dest)) {
      this.modes.set(dest, COMPAT_MODE.UNDEF);
    }

    dest.postMessage(
      {
        type: "Capture",
        module: "AuviousCobrowser",
        payload: init,
      } as IInitCapture,
      "*"
    );
  }

  /** @deprecated */
  public sendSetEvents(events: IEventOptions, dest: WindowProxy) {
    dest.postMessage(
      {
        type: "Request",
        module: "AuviousCobrowser",
        payload: {
          method: "setEvents",
          payload: events,
        },
      } as IRequest,
      "*"
    );
  }

  /** @deprecated */
  public sendIdentifyDocument(dest: WindowProxy) {
    dest.postMessage(
      {
        type: "Response",
        module: "AuviousCobrowser",
        payload: {
          method: "identifyDocument",
          result: this.target.generateId(),
        },
      } as IResponse,
      "*"
    );
  }

  private compatPorts = new Map<WindowProxy, MessagePort>();

  /** @deprecated */
  public compatRegisterDocument(
    data: IRequestRegisterDocument,
    source: WindowProxy
  ) {
    const dr: DocumentRegistered = {
      type: "DocumentRegistered",
      module: "AuviousCobrowser",
      port: null,
      id: data.payload.documentId,
      // just for the ts
      init: {
        targetId: -1,
        parentId: data.payload.parentId,
        allowOrigins: this.target.options.allowOrigins,
        user: this.target.options.user,
      },
    };

    if (data.payload.parentId !== this.target.documentId) {
      this.port.postMessage(dr);
    } else {
      const mc = new MessageChannel();
      dr.port = mc.port1;
      this.port.postMessage(dr, [mc.port1]);
      this.compatPorts.set(source, mc.port2);

      mc.port2.onmessage = (
        ev: MessageEvent<FlushRequest | SetEvents | RenderEvent>
      ) => {
        switch (ev.data.type) {
          case "FlushRequest":
            source.postMessage(
              {
                module: "AuviousCobrowser",
                type: "Request",
                payload: {
                  method: "flush",
                } as IRequestFlush,
              } as IRequest,
              "*"
            );

            break;
          case "SetEvents":
            this.sendSetEvents(ev.data.events, source);
            break;
          case "Render":
            source.postMessage(
              {
                module: "AuviousCobrowser",
                type: "Request",
                payload: {
                  method: "render",
                  payload: ev.data.event,
                } as IRequestRender,
              } as IRequest,
              "*"
            );

            break;
        }
      };
    }
  }

  private onMessage = (
    ev: MessageEvent<
      | PleaseRegister
      | StatusRequest
      | DocumentRegistered
      | RegistrationRequest
      | AbsolutePositionRequest
      | AbsolutePositionReset
      | Cleanup
      | IDocumentReady
      | IRequest
      | IResponse
    >
  ) => {
    if (ev.data.module !== "AuviousCobrowser") return;
    else if (
      this.target.allowedOrigins.length &&
      !this.target.allowedOrigins.includes(ev.origin)
    ) {
      console.warn("Cobrowser: Ignored message from origin", ev.origin);
      return;
    }

    switch (ev.data.type) {
      // from parent
      case "PleaseRegister":
        (this.target as Iframe).onPleaseRegister(ev.data);
        break;
      // from child
      case "StatusRequest":
        this.modes.set(ev.source as WindowProxy, COMPAT_MODE.NEW);
        this.target.onStatusRequest(ev.data, ev.source as WindowProxy);
        break;
      // from child
      case "RegistrationRequest":
        this.modes.set(ev.source as WindowProxy, COMPAT_MODE.NEW);
        this.target.onRegistrationRequest(ev.data, ev.source as WindowProxy);
        break;
      // from parent
      case "DocumentRegistered":
        this.port = ev.data.port;
        this.port.onmessage = this.onWorkerMessage;
        (this.target as Iframe).onDocumentRegistered(ev.data);
        break;
      // from child
      case "AbsolutePositionRequest":
        this.target.resetAbsolutePosition({ left: 0, top: 0 });
        break;
      // from parent
      case "AbsolutePositionReset":
        this.target.resetAbsolutePosition(ev.data.coords);
        break;
      // from parent
      case "Cleanup":
        this.target.cleanup();
        break;
      case "DocumentReady":
        // from child
        this.modes.set(ev.source as WindowProxy, COMPAT_MODE.OLD);
        this.target.onDocumentReady(ev.source as WindowProxy);
        break;
      case "Request":
        switch (ev.data.payload.method) {
          case "registerDocument":
            this.modes.set(ev.source as WindowProxy, COMPAT_MODE.OLD);

            // new documents register with the id they have been previously given
            this.compatRegisterDocument(
              ev.data.payload,
              ev.source as WindowProxy
            );

            break;
          case "identifyDocument":
            // give id to new documents
            this.sendIdentifyDocument(ev.source as WindowProxy);
            break;
          case "getPosition":
            // for root only
            this.target.resetAbsolutePosition({ left: 0, top: 0 });
            break;
          // ignored in new versions
          // case "removeDocument":
          //   break;
        }
        break;
      case "Response":
        if (ev.data.payload.method === "flush") {
          const port = this.compatPorts.get(ev.source as WindowProxy);

          if (port) {
            const events =
              // @ts-expect-error
              ev.data.payload.result.events || ev.data.payload.result;
            // @ts-expect-error
            const attachments = ev.data.payload.result.attachments || [];

            if (events) {
              port.postMessage(
                { type: "FlushResponse", events } as FlushResponse,
                attachments
              );
            }
          }
        }

        break;
    }
  };

  public sendPageUpdate(
    event: PageUpdateV3 | CanvasImageBitmap,
    transfer: Transferable[] = []
  ) {
    this.port.postMessage(
      { type: "PageUpdate", event } as PageUpdate,
      transfer
    );
  }

  public sendAbsolutePositionRequest() {
    this.port.postMessage({
      type: "AbsolutePositionRequest",
      module: "AuviousCobrowser",
    } as AbsolutePositionRequest);
  }

  public sendFlushResponse(
    events: Array<PageUpdateV3 | CanvasImageBitmap>,
    attachments: Transferable[] = []
  ) {
    this.port.postMessage(
      { type: "FlushResponse", events } as FlushResponse,
      attachments
    );
  }

  public sendFlushRequest() {
    this.port.postMessage({ type: "FlushRequest" } as FlushRequest);
  }

  public sendRender(ev: PageUpdateV3) {
    this.port.postMessage({
      type: "Render",
      event: ev,
    } as RenderEvent);
  }

  private onWorkerMessage = (
    ev: MessageEvent<
      FlushRequest | Batch | SetEvents | RenderEvent | AbsolutePositionRequest
    >
  ) => {
    switch (ev.data.type) {
      case "Batch":
        if (this.target.isRoot) {
          (this.target as Page).onBatch(ev.data.data);
        }

        break;
      case "FlushRequest":
        this.target.onFlushRequest();
        break;
      case "SetEvents":
        this.target.onSetEvents(ev.data.events);
        break;
      case "Render":
        this.target.render(ev.data.event);
        break;
      case "AbsolutePositionRequest":
        this.target.resetAbsolutePosition({ left: 0, top: 0 });
        break;
    }
  };

  public cleanup() {
    if (this.port) {
      this.port.close();
      this.port.onmessage = null;
    }

    this.compatPorts.clear();
    this.target.getWindow()?.removeEventListener("message", this.onMessage);

    const iframes = this.target.trackedIframes;

    if (!iframes) return;

    for (let i = 0; i < iframes.length; i++) {
      const win = iframes[i].contentWindow;
      const mode = this.modes.get(win);

      if (mode === COMPAT_MODE.NEW) {
        win.postMessage(
          {
            module: "AuviousCobrowser",
            type: "Cleanup",
          } as Cleanup,
          "*"
        );
      } else if (mode === COMPAT_MODE.OLD) {
        win.postMessage(
          {
            module: "AuviousCobrowser",
            type: "Request",
            payload: {
              method: "cleanup",
            } as IRequestCleanup,
          } as IRequest,
          "*"
        );
      }
    }
  }
}
